import React from 'react'
import Section from './Section'
import SectionHeader from './SectionHeader'
import CenteredColumns from './CenteredColumns'
import './TeamBiosSection.scss'

function TeamBiosSection(props) {
  const items = [
    {
      src: '/models/michael.glb',
      modelAlt: 'michael',
      name: 'Michael',
      role: 'Chief Product Officer',
      bio: `Michael has 10 years' experience teaching English as a foreign language, 5 of those using VR. He is responsible for ensuring all the Lingospaces content is educational, engaging and aligned to evidence-based frameworks.`,
    },
    {
      src: '/models/matthubscreator.glb',
      modelAlt: 'matt',
      name: 'Matt',
      role: 'Chief Technical Officer',
      bio:
        'Matt builds unique VR websites and interactive educational content. He is on a mission to use the power of the web to bring people together and share knowledge',
      link: 'https://twitter.com/MattBCool',
    },
  ]

  return (
    <Section
      color={props.color}
      size={props.size}
      backgroundImage={props.backgroundImage}
      backgroundImageOpacity={props.backgroundImageOpacity}>
      <div className='container'>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={3}
          spaced={true}
          className='has-text-centered'
        />
        <CenteredColumns>
          {items.map((item, index) => (
            <div
              className='column is-half-tablet is-one-third-desktop is-flex'
              key={index}>
              <div className='TeamBiosSection__card card is-flex'>
                <div className='TeamBiosSection__card-content card-content is-flex has-text-centered'>
                  <div className='TeamBiosSection__avatar-wrapper'>
                    <model-viewer
                      src={item.src}
                      alt={item.modelAlt}
                      loading='auto'
                      camera-controls></model-viewer>
                  </div>
                  <div className='TeamBiosSection__details'>
                    <p className='is-size-5'>{item.name}</p>
                    <p className='is-size-7 is-uppercase has-text-weight-semibold'>
                      {item.role}
                    </p>
                    <p className='TeamBiosSection__bio'>{item.bio}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </CenteredColumns>
      </div>
    </Section>
  )
}

export default TeamBiosSection
