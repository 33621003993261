import React from 'react'
import Section from './Section'
import TeamBiosSection from './TeamBiosSection'
import './Team.scss'

function Team(props) {
  return (
    <Section
      color={props.color}
      size={props.size}
      backgroundImage={props.backgroundImage}
      backgroundImageOpacity={props.backgroundImageOpacity}>
      <div className='container'>
        <div className='columns is-desktop'>
          <TeamBiosSection />
        </div>
      </div>
    </Section>
  )
}

export default Team
