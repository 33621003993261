import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Newsletter from "./Newsletter";
import "./NewsletterSection.scss";

function NewsletterSection(props) {
  return (
    <Section
      color={props.color}
      size={props.size}
      backgroundImage={props.backgroundImage}
      backgroundImageOpacity={props.backgroundImageOpacity}
    >
      <div className="container has-text-centered">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={3}
          spaced={false}
        />
        <div className="NewsletterSection__form-container">
          <Newsletter
            buttonText={props.buttonText}
            buttonColor={props.buttonColor}
            buttonInverted={props.buttonInverted}
            inputPlaceholder={props.inputPlaceholder}
            subscribedMessage={props.subscribedMessage}
          />
        </div>
      </div>
    </Section>
  );
}

export default NewsletterSection;
