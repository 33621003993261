import React from 'react'
import Team from './../components/Team'
import VideoSection from './../components/VideoSection'

function AboutPage(props) {
  return (
    <>
      <Team
        color='white'
        size='medium'
        backgroundImage=''
        backgroundImageOpacity={1}
        title='About Lingo Spaces'
        subtitle='Lingo Spaces'
        image='https://uploads.divjoy.com/undraw-beer_celebration_cefj.svg'
        buttonText='Get Started'
        buttonColor='primary'
        buttonInverted={false}
        buttonPath='/pricing'
      />
      <VideoSection
        color='white'
        size='medium'
        backgroundImage=''
        backgroundImageOpacity={1}
        title='Walkthrough with Michael'
        subtitle=''
        isYouTube={true}
        embedUrl='https://www.youtube.com/embed/iq-a0EJxJxg'
      />
    </>
  )
}

export default AboutPage
