import React, { Fragment } from 'react'

function VideoEmbed(props) {
  return (
    <Fragment>
      <video controls muted autoPlay={true} loop width='750'>
        <source
          src='/video/d0f4ec2f1b0600318f1923ea9e03b7ae.mp4'
          type='video/mp4'
        />
      </video>
    </Fragment>
  )
}

export default VideoEmbed
