import React from 'react'
import './../styles/global.scss'
import IndexPage from './index'
import AboutPage from './about'
import { Switch, Route, Router } from './../util/router.js'
import Navbar from './../components/Navbar'
/* import FirebaseActionPage from "./firebase-action.js"; */
import NotFoundPage from './not-found.js'

/* import { AuthProvider } from "./../util/auth.js"; */

function App(props) {
  return (
    /*     <AuthProvider> */
    <Router>
      <Navbar color='white' spaced={true} logo='/images/lingospaces.png' />
      <Switch>
        <Route exact path='/' component={IndexPage} />
        <Route exact path='/about' component={AboutPage} />
        {/*   <Route exact path="/firebase-action" component={FirebaseActionPage} /> */}

        <Route component={NotFoundPage} />
      </Switch>
    </Router>
    /*     </AuthProvider> */
  )
}

export default App
