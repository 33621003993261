import React from 'react'

import HeroSection from './../components/HeroSection'
import FeaturesSection from './../components/FeaturesSection'
import NewsletterSection from './../components/NewsletterSection'
import HeroSection2 from './../components/HeroSection2'
import { RoughNotation } from 'react-rough-notation'

function IndexPage(props) {
  const heroTitle = (
    <>
      <span style={{ color: '#4A4A4A' }}>Lingo Spaces </span>
      <RoughNotation
        type='underline'
        brackets={['left', 'top', 'right', 'bottom']}
        strokeWidth={5}
        animationDuration={'400'}
        show={true}
        color='#Fe7860'>
        <span style={{ color: '#4A4A4A' }}>Remote Language Training</span>
      </RoughNotation>
    </>
  )
  const heroSubTitle = (
    <>
      <span style={{ color: '#4A4A4A' }}>
        Engaging 360° Virtual Reality Experiences
      </span>

      <RoughNotation
        type='highlight'
        strokeWidth={6}
        animationDuration={'1300'}
        show={true}
        color='#5066f0'>
        <span style={{ color: '#F5F5F5', padding: '5px' }}>
          accessible from your browser
        </span>
      </RoughNotation>
    </>
  )
  return (
    <>
      <HeroSection
        color='white'
        size='large'
        backgroundImage='/images/training.svg'
        backgroundImageOpacity={0.7}
        title={heroTitle}
        subtitle={heroSubTitle}
        buttonText='Get Started'
        buttonColor='primary'
        buttonInverted={false}
        buttonPath='/'
      />
      <FeaturesSection
        color='white'
        size='medium'
        backgroundImage=''
        backgroundImageOpacity={1}
        image='https://uploads.divjoy.com/undraw-chatting_2yvo.svg'
      />
      <HeroSection2
        color='white'
        size='medium'
        backgroundImage=''
        backgroundImageOpacity={1}
        title='Try Out One of Our Free Spaces'
        subtitle='Sign up to receive another free sample!'
        image='/images/cinema-main.png'
        buttonText='Try now'
        buttonColor='primary'
        buttonInverted={false}
        buttonPath='https://hubs.mozilla.com/QmZZWj8/lingospaces-ielts-cinema'
      />
      <NewsletterSection
        color='white'
        size='medium'
        backgroundImage=''
        backgroundImageOpacity={1}
        title='Like our Free Sample Space?'
        subtitle='Get in touch so you can get use new spaces as they are released'
        buttonText='Sign up'
        buttonColor='primary'
        buttonInverted={false}
        inputPlaceholder='Enter your email'
        subscribedMessage='You are now subscribed 🙌'
      />
    </>
  )
}

export default IndexPage
