import React from 'react'
import Section from './Section'
import VideoEmbed from './VideoEmbed'
import './FeaturesSection.scss'

function FeaturesSection(props) {
  const items = [
    {
      title: 'Fun and Interactive',
      description:
        'Exploring how spatial computing fits seamlessly into the foreign language-teachers toolbox.',
      iconClass: 'fas fa-gamepad',
      iconColor: 'primary',
    },
    {
      title: 'Curated Lesson Plans',
      description:
        'Turn key remote experiences with years of experience built in.',
      iconClass: 'fas fa-book',
      iconColor: 'primary',
    },
    {
      title: 'Accessible',
      description: 'Access with a link across multiple devices.',
      iconClass: 'fas fa-universal-access',
      iconColor: 'primary',
    },
  ]

  return (
    <Section
      color={props.color}
      size={props.size}
      backgroundImage={props.backgroundImage}
      backgroundImageOpacity={props.backgroundImageOpacity}>
      <div className='container'>
        <div className='columns is-vcentered is-desktop'>
          <div className='column'>
            <VideoEmbed />
          </div>
          <div className='column is-1' />
          <div className='column is-5-desktop'>
            {items.map((item, index) => (
              <div
                className='FeaturesSection__feature columns is-vcentered is-variable is-4 is-mobile'
                key={index}>
                <div className='column is-narrow'>
                  <div
                    className={`FeaturesSection__icon icon has-text-${item.iconColor}`}>
                    <i className={`${item.iconClass}`} />
                  </div>
                </div>
                <div className='column'>
                  <h3 className='FeaturesSection__title title has-text-weight-bold is-spaced is-5'>
                    {item.title}
                  </h3>
                  <p className='subtitle is-6 has-text-grey'>
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Section>
  )
}

export default FeaturesSection
