import React, { useState } from 'react'
import { Link } from './../util/router.js'
/* import { useAuth } from "./../util/auth.js"; */

function Navbar(props) {
  /* const auth = useAuth(); */
  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <nav
      style={{ padding: '8px 0px 0px 8px' }}
      className={
        'navbar' +
        (props.color ? ` is-${props.color}` : '') +
        (props.spaced ? ' is-spaced' : '')
      }>
      <div className='container'>
        <div className='navbar-brand'>
          <div className='navbar-item'>
            <Link to='/'>
              <img className='image' src={props.logo} alt='Logo' />
            </Link>
          </div>
          <div
            className={'navbar-burger burger' + (menuOpen ? ' is-active' : '')}
            onClick={() => setMenuOpen(!menuOpen)}>
            <span />
            <span />
            <span />
          </div>
        </div>
        <div className={'navbar-menu' + (menuOpen ? ' is-active' : '')}>
          <div className='navbar-end'>
            <Link className='navbar-item' to='/about'>
              About
            </Link>

            {
              /* auth.user */ false && (
                <div className='navbar-item has-dropdown is-hoverable'>
                  <Link className='navbar-link' to='/'>
                    Account
                  </Link>
                  <div className='navbar-dropdown is-boxed'>
                    <Link className='navbar-item' to='/'>
                      Dashboard
                    </Link>
                    <Link className='navbar-item' to='/'>
                      Settings
                    </Link>
                    <hr className='dropdown-divider' />
                    <Link
                      className='navbar-item'
                      to='/'
                      onClick={(e) => {
                        e.preventDefault()
                        /* auth.signout(); */
                      }}>
                      Sign out
                    </Link>
                  </div>
                </div>
              )
            }

            {
              /* !auth.user */ true && (
                <>
                  {/*   <Link className="navbar-item" to="/">
                Sign in
              </Link>
              <div className="navbar-item">
                <Link className="button is-primary" to="/">
                  Sign Up
                </Link>
              </div> */}
                </>
              )
            }
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
